<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Recipient</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="recipient-form">
          <v-text-field
            label="Email"
            v-model="fields.email"
            type="email"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="recipient-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      fields: {
        email: null,
      },
      recipient: null,
      errors: {},
    };
  },

  methods: {
    openForm(recipient = null) {
      if (recipient !== null) {
        this.isEditing = true;
        this.recipient = recipient;
        this.fields.email = recipient.email;
      }

      this.dialog = true;
    },

    resetForm() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields.email = null;
      this.recipient = null;
      this.errors = {};
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.recipientId = this.recipient.id;
      }

      this.$store
        .dispatch("contactForms/saveRecipient", payload)
        .then(() => {
          this.resetForm();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
