<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Recipients</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Recipients"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.recipientForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Recipient</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  class="mr-2"
                  :to="{ name: 'module-contactforms-recipients-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Recipients</span>
            </v-tooltip>
            <v-btn depressed light :to="{ name: 'module-contactforms' }"
              ><v-icon left>mdi-arrow-left</v-icon> Back to Submissions</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="recipients"
        no-data-text="There are currently no recipients"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.recipientForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteDialog(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <RecipientForm ref="recipientForm" />
    <v-dialog v-model="deleteDialog.open" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Recipient</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteDialog.recipient.email }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteDialog"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteDialog.loading"
            @click="saveDeleteDialog"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RecipientForm from "./components/RecipientForm";

export default {
  components: {
    RecipientForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Contact Forms",
          disabled: false,
          exact: true,
          to: { name: "module-contactforms" },
        },
        {
          text: "Recipients",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteDialog: {
        open: false,
        recipient: {},
        loading: false,
      },
    };
  },

  computed: {
    recipients() {
      let recipients = this.$store.state.contactForms["recipients"];

      if (this.searchTerm !== "") {
        recipients = recipients.filter(r => {
          const email = r.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return email.includes(searchTerm);
        });
      }

      return recipients;
    },
  },

  methods: {
    openDeleteDialog(recipient) {
      this.deleteDialog.recipient = recipient;
      this.deleteDialog.open = true;
    },

    resetDeleteDialog() {
      this.deleteDialog.open = false;
      this.deleteDialog.recipient = {};
      this.deleteDialog.loading = false;
    },

    saveDeleteDialog() {
      this.deleteDialog.loading = true;

      this.$store
        .dispatch("contactForms/deleteRecipient", {
          appId: this.$route.params.id,
          recipientId: this.deleteDialog.recipient.id,
        })
        .then(() => {
          this.resetDeleteDialog();
        })
        .catch(() => {
          this.deleteDialog.loading = false;
        });
    },
  },
};
</script>
